/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"
import "../assets/css/styles.scss"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
	<MainLayout
		data={data}
		children={children}
        // location={location}
        // title={title}
        // description={description}
        // keywords={keywords}
        // previewImageUrl={previewImageUrl || data.defaultImage.fixed.src}
        // structuredDataJson={structuredDataJson}
	/>
  )
}


class MainLayout extends React.Component {
	componentDidMount() {
	  try {
		this.UIkit = require('uikit/dist/js/uikit')
		this.Icons = require('uikit/dist/js/uikit-icons')
		this.UIkit.use(this.Icons)
	  } catch (e) {
		console.error(e)
	  }
	}
	render() {
	//   const pageDescription =
	// 	this.props.description !== undefined
	// 	  ? this.props.description
	// 	  : this.props.data.site.siteMetadata.description
  
	//   const pageKeywords =
	// 	this.props.keywords !== undefined
	// 	  ? this.props.keywords.map(k => k.toLowerCase()).join(',')
	// 	  : this.props.data.site.siteMetadata.keywords
  
	//   const canonicalUrl = `${this.props.data.site.siteMetadata.siteUrl}${
	// 	this.props.location.pathname
	//   }`
  
	//   const googleSiteVerification = 'NYAneve0llvi3Mmooz40QrY1GZNCNqsgiqYM-3DSMS4'
  
	  return (
		<div className="main-container">
		  {/* <Helmet
			title={this.props.title}
			defaultTitle={this.props.title}
			titleTemplate="%s | Bay Phillips"
			meta={[
			  { name: 'description', content: pageDescription },
			  { name: 'keywords', content: pageKeywords },
			  { name: 'og:title', content: this.props.title },
			  { name: 'og:description', content: pageDescription },
			  { name: 'og:url', content: canonicalUrl },
			  {
				name: 'og:image',
				content: `https:${this.props.previewImageUrl}`,
			  },
			  { name: 'twitter:card', content: 'summary' },
			  { name: 'twitter:creator', content: '@bayphillips' },
			  { name: 'twitter:site', content: '@bayphillips' },
			  { name: 'twitter:image:alt', content: this.props.title },
			  {
				name: 'google-site-verification',
				content: googleSiteVerification,
			  },
			]}
			script={[
			  {
				type: 'application/ld+json',
				innerHTML: `${JSON.stringify(this.props.structuredDataJson)}`,
			  },
			]}
		  >
			<html lang="en" />
		  </Helmet> */}
		  {/* <NavigationBar
			location={this.props.location}
			menu={this.props.data.menu.edges[0]}
		  /> */}
		  {this.props.children}
		  {/* <Footer /> */}
		</div>
	  )
	}
  }
  
  MainLayout.propTypes = {
	data: PropTypes.object.isRequired,
  }

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout